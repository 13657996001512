<template>
    <b-card>
        <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
                <b-card-title></b-card-title>
                <b-card-sub-title></b-card-sub-title>
            </div>
            <!-- Card Actions -->
            <div class="heading-elements">
                <ul class="list-inline mb-0">
                    <li>
                        <feather-icon icon="HelpCircleIcon" v-b-tooltip.hover.left="'Tooltip on top!'" size="20" />
                    </li>
                </ul>
            </div>
        </div>
        <b-card-body>
            <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left">
                <b-tab v-for="fileUploadTab in fileUploadTabs" :title="fileUploadTab.tabName"
                    :key="fileUploadTab.tabName">
                    <b-card-text>
                        <b-card-title>
                            Cargar Documento {{ fileUploadTab.tabName }}
                        </b-card-title>
                        <!-- info -->
                        <b-alert variant="secondary" show>
                            <div class="alert-body">
                                <span></span>
                                <b-link target="_blank" @click="downloadTemplateFile(fileUploadTab.templateFile)">
                                    Descargar Ejemplo
                                </b-link>
                            </div>
                        </b-alert>
                        <b-row class="match-height">
                            <b-col md="8">
                                <b-form-file ref="form-file-inputs"
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    :placeholder="'Selecciona ' + fileUploadTab.tabName"
                                    drop-placeholder="Arrastra el archivo hasta acá ..." v-model="fileUploadTab.file">
                                </b-form-file>
                            </b-col>
                            <b-col>
                                <b-button variant="outline-primary" :disabled="!fileUploadTab.file"
                                    @click="uploadDocument(fileUploadTab)">
                                    <b-spinner v-if="fileUploadTab.isUploading" small class="mr-50" />
                                    <feather-icon v-else icon="UploadIcon" class="mr-50" />
                                    Cargar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card-body>
    </b-card>
</template>
<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BIconNodeMinusFill,
    BTabs,
    BSpinner,
    BAlert,
    BTab,
    BLink,
    VBPopover,
    VBTooltip,
    BFormGroup,
    BFormInvalidFeedback,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import useApiServices from "@/services/useApiServices.js";

import axios from "@axios";

export default {
    name: "CargaData",
    data() {
        return {
            fileUploadTabs: [
                {
                    tabName: "Stock de Lotes",
                    type: "STOCK_LOTES",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=STOCK_LOTES",
                },
                {
                    tabName: "Contratos",
                    type: "CONTRATOS",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=CONTRATOS",
                },
                {
                    tabName: "SKU/Precios/Calidad",
                    type: "SKU_PRECIOS_CALIDAD",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=SKU_PRECIOS_CALIDAD",
                },
                {
                    tabName: "Lotes Blendeados",
                    type: "LOTES_BLENDEADOS",
                    file: null,
                    isUploading: false,
                    templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=LOTES_BLENDEADOS",
                },
            ],
        };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BCardHeader,
        BFormFile,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BCardBody,
        BFormSelect,
        BTabs,
        VBPopover,
        VBTooltip,
        BTab,
        BLink,
        BAlert,
        BSpinner,
        BFormInvalidFeedback,
        BFormGroup,
    },

    directives: {
        "b-tooltip": VBTooltip,
        "b-popover": VBPopover,
    },

    mounted() {
        axios.get("http://camanchaca.localhost:49257/api/test")
            .then((response) => {
                console.log({ tenant_response: response });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    methods: {
        downloadTemplateFile(url) {
            return useApiServices.getDownloadFile(url);
        },
        uploadDocument(fileUploadTab) {
            var formData = new FormData();
            formData.append("documentFile", fileUploadTab.file);
            formData.append("documentType", fileUploadTab.type);

            if (fileUploadTab.file) {
                fileUploadTab.isUploading = true;

                useApiServices.postUploadDocument(formData, this)
                    .then((response) => {
                        console.log(response);
                        fileUploadTab.isUploading = false;

                        this.$toast(
                            {
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: `Carga Exitosa`,
                                    icon: "UploadIcon",
                                    variant: "success",
                                    html: `El documento <b>${fileUploadTab.file.name}</b> se ha cargado exitosamente.`,
                                },
                            },
                            {
                                onClose: () => {
                                    fileUploadTab.file = null;
                                },
                            }
                        );
                    })
                    .catch((error) => {
                        fileUploadTab.isUploading = false;

                        this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                                title: `Ocurrio un Error`,
                                icon: "UploadIcon",
                                variant: "danger",
                                html: `Ha ocurrido un error al subir el documento <b>${fileUploadTab.file.name}</b>.`,
                            },
                        });
                    });
            } else {
            }
        },
    },
};
</script>

<style scoped>
.carga-data {
    margin-top: 4%;
}
</style>

